.player-stats-card {
  font-family: 'CSGO-Elisa';
  transition: opacity 0.2s ease-out;

  &.bc-open {
    opacity: 1;
  }

  &.bc-close {
    opacity: 0;
  }

  width: 674px;
  height: 207px;
  background: #171616;
  position: absolute;
  top: 60px;
  color: white; 
  z-index: -2;

  .player {
    width: 350px;
  }

  .player_name {
    text-transform: uppercase;
    font-size: 1.5em;
    margin-top: 0px;
  }

  .player_nickname {
    font-size: 2.5em;
    margin-top: -20px;
  }

  .player_image {
    width: 175px;
    margin-top: -100px;
  }

  .player_image_gradient {
    background: linear-gradient(180deg, rgba(0,36,71,0) 0%, rgba(23,22,22,1) 75%);
    height: 100px;
    width: 235px;
    position: absolute;
    left: 10px;
    top: 100px;
  }

  .team_logo {
    position: absolute;
    left: 25px;
    width: 321px;
    overflow: hidden;

    img {
      z-index: -1;
      position: relative;
      left: 10px;
      width: 150px;
      margin-top: -20px;
      margin-left: 15px;
    }
  }

  .stat_wrapper {
    width: 230px;
  }

  .stat_title {
    font-size: 2em;
    margin-top: -0.5em;
    text-transform: uppercase;
  }
  
  .stat_value_wrapper {
    width: 180px;
    padding-right: 30px;
  }

  .points_wrapper.team_left {
    margin-top: 0px;
    padding-top: 10px;
    left: 440px;
    top: 481px;
    position: absolute;
  }
  .points_wrapper.team_right {
    margin-top: 0px;
    padding-top: 10px;
    right: 440px;
    top: 481px;
    position: absolute;
  }
  .points_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .points_wrapper .points {
    height: 45px;
    display: flex;
    margin-bottom: 17px;
    justify-content: center;
    width: 90px;
    align-items: center;
    background-color: #414141;
  }
  .points_wrapper .point_title {
    color: white;
    font-size: 30px;
    margin: 0px;
  }

  table {
    width: 100%;
    margin: 17px auto;
  }

  .line_wrapper {
    width: 180px;
  }

  .line {
    height: 4px;
    width: 75px;
    background-color: white;
    margin-top: -1em;
  }
}