body {
  color: $secondary;
  background: transparent;
}

@font-face {
  font-family: 'LeaderboardFont';
  src: url('/fonts/bebasneue.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Black.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoThin';
  src: url('/fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'agency_fb';
  src: url('/assets/fonts/Agency-FB.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'IntegralCD-Demi-Bold-Oblique';
  src: url('/assets/fonts/IntegralCF-DemiBoldOblique.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'IntegralCD-Regular';
  src: url('/assets/fonts/IntegralCF-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'big_noodle_titling';
  src: url('/assets/fonts/big-noodle-titling.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neue';
  src: url('/assets/fonts/BebasNeue.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'muli';
  src: url('/assets/fonts/muli.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ael';
  src: url('/assets/fonts/ael.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ael-title';
  src: url('/assets/fonts/ael-title.woff') format('woff');
}

@font-face {
  font-family: 'ael-subtitle';
  src: url('/assets/fonts/ael-subtitle.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'anton-regular';
  src: url('/assets/fonts/Anton-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'teko-bold';
  src: url('/assets/fonts/Teko-Bold.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'teko-light';
  src: url('/assets/fonts/Teko-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'teko-medium';
  src: url('/assets/fonts/Teko-Medium.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'teko-regular';
  src: url('/assets/fonts/Teko-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'teko-semibold';
  src: url('/assets/fonts/Teko-SemiBold.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'valorant';
  src: url('/assets/fonts/Valorant_Font.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'sdp';
  src: url('/assets/fonts/sdp.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'machine';
  src: url('/assets/fonts/machine.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'noto-bold';
  src: url('/assets/fonts/NotoSansJP-Bold.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'noto-black';
  src: url('/assets/fonts/NotoSansJP-Black.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'RussoOne-Regular';
  src: url('/assets/fonts/RussoOne-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gobold-Thin';
  src: url('/assets/fonts/Gobold-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: 'rrb-regular';
  src: url('/assets/fonts/rrb-regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'rrb-bold';
  src: url('/assets/fonts/rrb-bold.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ESL Legend';
  src: url('/assets/fonts/legend-light.woff') format('woff'),
       url('/assets/fonts/legend-light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'ESL Legend';
  src: url('/assets/fonts/legend-regular.woff') format('woff'),
       url('/assets/fonts/legend-regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'ESL Legend';
  src: url('/assets/fonts/legend-bold.woff') format('woff'),
       url('/assets/fonts/legend-bold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Calps Sans';
  src: url('/assets/fonts/calpssans-light-webfont.woff') format('woff'),
       url('/assets/fonts/calpssans-light-webfont.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Calps Sans';
  src: url('/assets/fonts/calpssans-medium-webfont.woff') format('woff'),
       url('/assets/fonts/calpssans-medium-webfont.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Calps Sans';
  src: url('/assets/fonts/calpssans-regular-webfont.woff') format('woff'),
       url('/assets/fonts/calpssans-regular-webfont.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Purista-Bold';
  src: url('/assets/fonts/Purista-Bold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Purista-Medium';
  src: url('/assets/fonts/Purista-Medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Purista-SemiBold';
  src: url('/assets/fonts/Purista-SemiBold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Purista-SemiBoldItalic';
  src: url('/assets/fonts/Purista-SemiBoldItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'CenturyGothicBold';
  src: url('/assets/fonts/CenturyGothicBold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'SonySketchEF';
  src: url('/assets/fonts/SonySketchEF.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Druk-Medium';
  src: url('/assets/fonts/Druk-Medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Tungsten-Semibold';
  src: url('/assets/fonts/Tungsten-Semibold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Druk-SuperItalic';
  src: url('/assets/fonts/Druk-SuperItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukTextWide-BoldItalic';
  src: url('/assets/fonts/DrukTextWide-BoldItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukWide-BoldItalic';
  src: url('/assets/fonts/DrukWide-BoldItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'BebasNeue Regular';
  src: url('/assets/fonts/BebasNeue Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukWide-SuperItalic';
  src: url('/assets/fonts/DrukWide-SuperItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukText-BoldItalic';
  src: url('/assets/fonts/DrukText-BoldItalic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukTextWide-Bold';
  src: url('/assets/fonts/DrukTextWide-Bold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Druk-Super';
  src: url('/assets/fonts/Druk-Super.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'DrukText-Medium';
  src: url('/assets/fonts/DrukText-Medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'CSGO-Elisa';
  src: url('/assets/fonts/elisa.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Predator-Bold';
  src: url('/assets/fonts/Predator-Bold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Predator-Regular';
  src: url('/assets/fonts/Predator-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'BarlowCondensed-Regular';
  src: url('/assets/fonts/BarlowCondensed-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'BarlowCondensed-Light';
  src: url('/assets/fonts/BarlowCondensed-Light.woff2') format('woff2');
  font-weight: 400;
}

.tournament-button-help .nav-link.active {
  background-color: $danger !important;

  .nav-icon {
    color: $secondary !important;
  }
}

.overlay-button-help .nav-link.active {
  background-color: $success !important;

  .nav-icon {
    color: $secondary !important;
  }
}