.player-leaderboard {
  font-family: 'CSGO-Elisa';
  transition: opacity 0.2s ease-out;

  &.bc-open {
    opacity: 1;
  }

  &.bc-close {
    opacity: 0;
  }

  width: 100%;
  height: 1080px;
  // background: #002447;
  background: transparent;
  position: absolute;
  color: white; 
  z-index: -2;

  h1 {
    margin: 0 !important;
  }

  table {
    margin: 90px auto;
    width: 1500px;
    height: 700px;

    .player_image {
      width: 240px;
    }

    .team_image {
      width: 75px;
    }
  }

  .leaderboard_title {
    font-size: 4em;
    text-transform: uppercase;
  }

  .player_placement {
    font-size: 6em;
  }

  .points_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .points_wrapper .points {
    display: flex;
    justify-content: center;
    width: 240px;
    height: 80px;
    align-items: center;
    background-color: #1b85ae;
    font-size: 5em;
    padding-bottom: 7px;
  }
  .points_wrapper .point_title {
    color: white;
    font-size: 30px;
    margin: 0px;
  }

  .player_info {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 3em;
    }

    img {
      width: 45px;
      margin-right: 10px;
    }
  }

  .twire-logo {
    width: 210px;
    margin-top: 20px;
  }
}