.player-stats-full-screen {
  font-family: 'CSGO-Elisa';
  text-transform: uppercase;
  transition: opacity 0.3s ease-out;
  
  &.bc-open {
    opacity: 1;
  }
  
  &.bc-close {
    opacity: 0;
  }
  
  margin: 0;
  padding: 0! important;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  color: white;
  background: transparent;
  // background: black;
  
  .team_player {
    height: 100vh;
    width: 100vh;
    position: absolute;
    top: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.5;
  
    img {
      position: fixed;
      left: -100px;
      bottom: 0;
      height: 100%;
    }
  }
  .team_player.team_left {
    left: -35vh;
  }
  .team_player.team_right {
    right: -35vh;
  }
  .wrapper .ElisaInv_logo {
    height: 200px;
  }
  .wrapper .logo_wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 310px;
  }
  
  .team_logo {
    line-height: 1;
    width: 100%;
    text-align: center;
  }
  
  .team_logo.team_left #playername {
    font-size: 30px;
    margin: 0px;
    text-align: center;
  
    span {
      color: #1a83ac;
    }
  }
  .team_logo.team_left #playernick {
    font-size: 47px;
    margin: 0px;
    text-align: center;
    text-transform: none;
  }
  .team_logo.team_right #rightPlayername {
    font-size: 25px;
    margin: 0px;
    text-align: right;
  }
  .team_logo.team_right #rightPlayernick {
    font-size: 47px;
    margin: 0px;
    text-align: right;
    text-transform: none;
  }
  .teams_info .versus {
    position: absolute;
    width: 100%;
    font-size: 4em;
    text-align: center;
    top: 300px;
  }
  
  .teams_info .player_info {
    padding-top: 75px;
    width: 100%;
  
    .team_logo_name {
      width: 100%;
      text-align: center;
      font-size: 28px;
  
      img {
        height: 75px;
      }
    }
  }
  
  .teams_info .player-name-container {
    width: 100%;
    text-align: center;
    h1 {
      font-size: 45px;
    }
  
    span {
      color: #2f98c2;
    }
  }
  
  .main-wrapper {
    width: 1920px;
    height: 1080px;
    position: relative;
    overflow: hidden;
  }
  .lines_container.left {
    position: absolute;
    text-align: center;
    top: 476px;
    font-size: 3em;
    left: 645px;
    height: 380px;
    width: 108px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .lines_container.right {
    position: absolute;
    text-align: center;
    top: 326px;
    font-size: 3em;
    left: calc(50% - 54px);
    height: 380px;
    width: 108px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  .lines_container .line {
    height: 5px;
    width: 100%;
    background-color: white;
  }
  .teams_info {
    display: flex;
    width: 100%;
  }
  .teams_info .team_logo {
    width: 100%;
    text-align: center;
  }
  .teams_info .team_logo img {
    height: auto;
    width: 110px;
  }
  .teams_info .team_logo.team_right {
    position: absolute;
    float: right;
    z-index: 1;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .teams_info .team_logo.team_left {
    position: absolute;
    float: left;
    z-index: 1;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .teams_info .team_logo.team_left img {
    margin-right: 10px;
  }
  
  .teams_info .team_logo.team_right img {
    margin-left: 10px;
  }
  
  .points_wrapper.team_left {
    margin-top: 0px;
    padding-top: 10px;
    left: 440px;
    top: 301px;
    position: absolute;
  }
  .points_wrapper.team_right {
    margin-top: 0px;
    padding-top: 10px;
    left: 62%;
    top: 331px;
    position: absolute;
  }
  .points_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .points_wrapper .points {
    height: 50px;
    display: flex;
    margin-bottom: 25px;
    justify-content: center;
    width: 125px;
    align-items: center;
    background-color: #1b85ae;
  }
  .points_wrapper .point_title {
    color: white;
    font-size: 30px;
    margin: 0px;
  } 
  .teams_info .maps_info {
    position: absolute;
    color: white;
    width: 33%;
    left: 33%;
    text-align: left;
    top: 306px;
    font-size: 3em;
  }
  .teams_info .maps_info .maps {
    margin-top: 1em;
    font-size: 0.75em;
    line-height: 48px;
  }
  
  .twire-logo {
    position: absolute;
    top: 730px;
    width: 100%;
    text-align: center;
  }
}